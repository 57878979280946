import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import fiveIronOptions from "./data/fiveIronOptions";
import "./SecondaryForm.css";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  city: Yup.string(),
  score: Yup.number()
    .required("Score is required")
    .integer("Score must be an integer")
    .min(-900, "Invalid score")
    .max(99999, "Invalid score"),
  selectedValue: Yup.string().required("Leaderboard is required"),
  fiveIronLocation: Yup.string(),
});

export default function SecondaryForm({
  onClose,
  isLeaderboardForm,
  formType = null,
  initialValues = {},
  refreshData,
}) {
  const [selectedValue, setSelectedValue] = useState(formType || "");
  const [fiveIronLocation, setFiveIronLocation] = useState("");

  const formik = useFormik({
    initialValues: {
      name: initialValues.name || "",
      city: initialValues.city || "",
      course: initialValues.course || "",
      score: initialValues.score || "",
      selectedValue: selectedValue,
      fiveIronLocation: fiveIronLocation || "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const leaderboardName =
        values.selectedValue === "five-iron" ? "5Iron" : values.selectedValue;
      const additionalData = {
        name: values.name,
        city:
          values.selectedValue === "five-iron"
            ? values.fiveIronLocation
            : values.city,
        course: values.course,
        score: values.score,
        leaderboard: leaderboardName,
        fiveIronLocation: values.fiveIronLocation,
      };

      console.log("Form submit", additionalData);

      const endpoint = "https://v-ext-michelob-ultra-api.vercel.app/api/score/leaderboards";

      try {
        const response = await fetch(endpoint, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(additionalData),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log("Form submitted successfully:", data);

        // Refresh the leaderboard data
        await refreshData();
      } catch (error) {
        console.error("Form submission error:", error);
      } finally {
        setSubmitting(false);
        onClose();
      }
    },
  });

  const handleSelectChange = (e) => {
    setSelectedValue(e.target.value);
    formik.setFieldValue("selectedValue", e.target.value);
  };

  const handleFiveIronSelectChange = (e) => {
    setFiveIronLocation(e.target.value);
    formik.setFieldValue("fiveIronLocation", e.target.value);
  };

  const formClass = isLeaderboardForm
    ? "secondary-form-leaderboard"
    : "secondary-form-popup";

  return (
    <div id="secondary-form" className={formClass}>
      <form onSubmit={formik.handleSubmit} className="form-styling">
        <div className="optional-info">
          <p>GET ON THE LEADERBOARD BY ADDING YOUR INFO BELOW.</p>
        </div>
        <input
          id="name"
          type="text"
          name="name"
          placeholder="NAME"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.name && formik.errors.name ? (
          <div>{formik.errors.name}</div>
        ) : null}


        {!formType && (
          <select
          id="selectedValue"
          name="selectedValue"
          value={formik.values.selectedValue}
          onChange={handleSelectChange}
          onBlur={formik.handleBlur}
          >
            <option value="" disabled>
              WHERE DID YOU PLAY
            </option>
            <option value="local">LOCAL COURSE</option>
            <option value="five-iron">FIVE IRON GOLF</option>
          </select>
        )}
        
        {(selectedValue === "local" || formType === "local") && (
          <input
            id="city"
            type="text"
            name="city"
            placeholder="CITY"
            value={formik.values.city}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        )}
        {formik.touched.city && formik.errors.city ? (
          <div>{formik.errors.city}</div>
        ) : null}

        {(selectedValue === "five-iron" || formType === "five-iron") && (
          <select
            name="fiveIronLocation"
            value={formik.values.fiveIronLocation}
            onChange={handleFiveIronSelectChange}
            onBlur={formik.handleBlur}
          >
            {fiveIronOptions.map((option) => (
              <option
                key={option.value}
                value={option.value}
                disabled={option.disabled}
              >
                {option.label}
              </option>
            ))}
          </select>
        )}
        {formik.touched.fiveIronLocation && formik.errors.fiveIronLocation ? (
          <div>{formik.errors.fiveIronLocation}</div>
        ) : null}

        <input
          id="score"
          type="number"
          name="score"
          placeholder="SCORE"
          value={formik.values.score}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.score && formik.errors.score ? (
          <div>{formik.errors.score}</div>
        ) : null}

        <button type="submit" className="submit-btn">
          SUBMIT
        </button>
        <button type="button" className="close-btn" onClick={onClose}>
          CLOSE
        </button>
      </form>
    </div>
  );
}
