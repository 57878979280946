import { useState, useEffect } from "react";
import NavBar from "./NavBar";
import "./Header.css";

export default function Header({ onLeaderBoardToggle, setExpanded }) {
  const [showNavBar, setShowNavBar] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showNavBar &&
        !event.target.closest(".navbar") &&
        !event.target.closest(".menu-icon")
      ) {
        setShowNavBar(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showNavBar]);

  return (
    <>
      <header>
        <div className="logo-container">
          <img
            src="https://images.complex.com/complex/image/upload/v1717189030/Custom/michelob/mich-x-complex_blk.png"
            alt="Michelob Ultra x Complex"
            className="logo"
          />
        </div>
        <div
          className={`menu-icon ${showNavBar ? "open" : ""}`}
          onClick={() => setShowNavBar(!showNavBar)}
        >
          <div className="bar top-bar"></div>
          <div className="bar middle-bar"></div>
          <div className="bar bottom-bar"></div>
        </div>
      </header>
      <NavBar
        onLeaderNavClick={onLeaderBoardToggle}
        className={showNavBar ? "open" : ""}
        setShowNavBar={setShowNavBar}
        setExpanded={setExpanded}
      />
    </>
  );
}
