import "./Footer.css";
export default function Footer() {
  return (
    <footer className="footer-container">
      <div className="footer-wrapper">
        <div className="footer-privacy-container">
          <div>
            <a title="Go to Complex" href="https://www.complex.com">
              <img className="footer-logo" src="https://svg.complex.com/svg/complex-logo-light.svg" alt="Complex logo" />
            </a>
          </div>
          <div className="footer-links">
            <a href="https://www.complex.com/terms" target="_blank" rel="noreferrer">TERMS OF USE</a>
            <a href="https://www.complex.com/privacy" target="_self">PRIVACY POLICY</a>
            <a href="https://www.complex.com/privacy#ca_privacy" target="_self">CALIFORNIA PRIVACY</a>
          </div>
        </div>
      </div>
      <div className="footer-legal-wrapper">
        <p>© COMPLEX</p>  
        <p>ENJOY RESPONSIBLY © 2024 ANHEUSER-BUSCH, MICHELOB ULTRA® LIGHT BEER, ST. LOUIS, MO.</p>
      </div>
    </footer>
  );
}