import arrowOpen from "../../images/triangle-open.png";
import arrowClose from "../../images/triangle-close.png";
import "./ToggleBar.css";

export default function ToggleBar({ expanded, toggleBoard }) {
  return (
    <div className="toggle-bar" onClick={toggleBoard}>
      <div className="toggle-content">
        <img
          src={expanded ? arrowClose : arrowOpen}
          alt={expanded ? "Collapse" : "Expand"}
          className="toggle-icon"
        />
        <div className="toggle-text">
          {expanded ? "COLLAPSE" : "SEE FULL"} LEADERBOARD
        </div>
        <img
          src={expanded ? arrowClose : arrowOpen}
          alt={expanded ? "Collapse" : "Expand"}
          className="toggle-icon"
        />
      </div>
    </div>
  );
}
