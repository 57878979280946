import "./NavBar.css";

export default function NavBar({
  className,
  onLeaderNavClick,
  setShowNavBar,
  setExpanded,
}) {
  return (
    <div className={`navbar ${className}`}>
      <ul className="nav-items">
        <li className="nav-item">
          <a
            href="#hero-section"
            onClick={(e) => {
              e.preventDefault();
              setShowNavBar(false);
              setExpanded(false);
            }}
          >
            SWEEPS
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#!"
            onClick={(e) => {
              e.preventDefault();
              onLeaderNavClick();
              setShowNavBar(false);
            }}
          >
            LEADERBOARD
          </a>
        </li>
      </ul>
    </div>
  );
}
