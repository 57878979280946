import { useState } from 'react'

import AgeGate from './components/forms/AgeGate'
import MainContent from './components/MainContent'
import './App.css';

function App() {
  const [ageVerified, setAgeVerified] = useState(false) 

  return (
    <div className="app">
      {!ageVerified && <AgeGate onVerified={() => setAgeVerified(true)}/>}
      {ageVerified && <MainContent />}
    </div>
  );
}

export default App;

