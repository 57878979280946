const leaderboardInfo = [
    {
      name: "grint",
      title: "MICHELOB ULTRA OPEN LEADERBOARD POWERED BY THEGRINT",
      formType: "default",
      joinMessage: [
        <div className="grint-message" key="download">
           <p>Download{" "}</p>
          <a
            href="https://app.adjust.com/1bcvlbkw?campaign=MichelobUltra&fallback=https%3A%2F%2Fthegrint.com%2F&redirect_macos=https%3A%2F%2Fthegrint.com%2F"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://images.complex.com/complex/image/upload/v1717189046/Custom/michelob/TheGrint.png"
              alt="TheGrint"
            />
          </a>
        </div>,
        <p>Create A Profile</p>,
        <p>Join & Post A Score to the Michelob Ultra Open Challenge</p>,
      ],
    },
    {
      name: "local",
      title: "MICHELOB ULTRA OPEN LOCAL COURSES LEADERBOARD",
      formType: "local",
      joinMessage: [""],
    },
    {
      name: "five-iron",
      title: "MICHELOB ULTRA OPEN FIVE IRON GOLF LEADERBOARD",
      formType: "five-iron",
      joinMessage: [""],
    },
  ];

  export default leaderboardInfo;