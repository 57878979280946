import "./TermsAndConditions.css";

export default function TermsAndConditions() {
  return (
    <div className="terms-and-conditions">
      <h4>COMPLEX X MICHELOB ULTRA®&nbsp;OPEN CHALLENGE</h4>
      <h4>OFFICIAL RULES</h4>
      <p>
        <strong>NO PURCHASE OR MOBILE DEVICE NECESSARY TO ENTER OR WIN.</strong>
        <strong>
          A PURCHASE OR MOBILE DEVICE WILL NOT INCREASE YOUR CHANCES OF WINNING.
          VOID WHERE PROHIBITED BY LAW.&nbsp;
        </strong>
      </p>
      <ol>
        <li>
          <strong>ELIGIBILITY: </strong>The Complex x Michelob Ultra Open
          Challenge (“Challenge”) is open to legal residents of the fifty (50)
          United States and District of Columbia who are twenty-one (21) years
          of age or older at the time of entry (each, an “Entrant”). Employees,
          officers and directors of Anheuser-Busch, LLC, (“Sponsor”), Commerce
          Media Holdings LLC, d/b/a NTWRK, teamDigital Promotions, Inc.
          (“Administrator”) and each of their respective parent companies,
          subsidiaries, affiliates, governors, shareholders, related entities,
          partners, partnerships, principals, wholesale distributors, retail
          licensees, sponsors, and advertising/promotion agencies and each of
          their respective officers, directors, shareholders, employees, agents,
          members, representatives, successors and assigns (collectively,
          "Released Parties"), and the immediate family members (parent, spouse,
          sibling, or child, and their respective spouses, regardless of where
          they reside) and those living in the same household of each (whether
          or not related) are not eligible to participate or win. This Challenge
          is subject to these official rules (“Official Rules”) and all
          applicable federal, state, and local laws, and regulations. Void where
          prohibited or restricted by law, rule or regulation. Participation
          constitutes entrant’s full and unconditional agreement to these
          Official Rules (“Official Rules”) and Sponsor’s and Administrator’s
          decisions which are final and binding in all matters related to this
          Challenge. Winning a prize is contingent upon fulfilling all
          requirements as set forth herein.&nbsp;
        </li>
        <li>
          <strong>CHALLENGE PERIOD: </strong>
          The Challenge begins at 12:00:00 PM Eastern Time (“<strong>ET</strong>
          ”) on 6/1/24 and ends at 11:59:59 PM ET on 8/31/24 (“Challenge
          Period”).
        </li>
        <li>
          <strong>HOW TO PARTICIPATE:</strong>
          During the Challenge Period, there are two (2) way to participate in
          the Challenge:&nbsp;
          <ol>
            <li className="indent">
              TheGrint app: Using your smart phone or other web-enabled device
              scan the Challenge specific quick response (“QR”) Code featured on
              Challenge specific promotional materials at participating golf
              courses and other channels designated by Sponsor then follow the
              prompts to access/download TheGrint app or download TheGrint app
              directly. Then: a) If you are not already a registered user of the
              TheGrint app, follow the prompts to create a TheGrint account (the
              download and registration are free, but are subject to the
              TheGrint Terms of Service and Privacy Policy); or b) If you are
              already a registered user of the TheGrint App, login to your
              account as directed. Once logged into your Grint App account tap
              the banners, buttons, or links to access the Challenge. Follow the
              Challenge participation instructions as directed to receive one
              entry into the Challenge; or&nbsp;
            </li>
            <li className="indent">
              Website: To enter the challenge for free without
              downloading/registering for the TheGrint App and/or playing golf
              visit{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://michelobultraopen2024.com/"
              >
                <span>michelobultraopen2024.com</span>
              </a>{" "}
              and click on the Challenge banners, buttons and/or links to access
              the Challenge registration form. Then follow the instructions
              provided to fully complete and submit the registration form to
              receive one (1) entry into the Prize Drawing.
            </li>
          </ol>
          <p>
            Limit of one (1) entry per person and per email address regardless
            of entry method for the duration of the Challenge Period. Additional
            entries received from any one person or email address thereafter
            during any Challenge Period will be void. Released Parties are not
            responsible for incomplete, late, garbled, lost, delayed or
            misdirected entries, failures or malfunctions of phones (including
            wireless phones/handsets), phone lines, cellular equipment towers,
            telephone systems, transmissions, defect or delay in transmission,
            transaction processing, interrupted or unavailable network, server,
            wireless service provider or other connections, miscommunications,
            failed computer hardware or software or technical failures, garbled,
            misrouted, scrambled transmissions, printing, typographical or other
            errors in these Official Rules, in any Challenge-related
            advertisements or other materials, or other errors or problems of
            any kind whether computer, network, cellular, human, electronic or
            otherwise relating to or in connection with this Challenge,
            including, without limitation, any errors or problems which may
            occur in connection with the administration of the Challenge, the
            processing of entries or transactions or in the announcement of the
            prizes.&nbsp; The use of automated or similar devices, including but
            not limited to entries submitted using any bot, macro, script, or
            sweepstakes service (or any other devices intended to automate any
            aspect of entry) to enter this Challenge is prohibited and any
            entries made through such means are void. Mechanically reproduced
            entries are void. Released Parties are not responsible for any
            injury or damage to entrant’s or any other person’s wireless phone/
            computer/device related to or resulting from participation in this
            Challenge. By entering this Challenge, entrants give their express
            permission to be contacted by Sponsor by telephone, e-mail and/or
            postal mail for Challenge purposes only. To scan a QR code, entrants
            must have a smart phone or other web-enabled device with a camera
            feature and/or QR scanner application and may incur message or data
            charges from their wireless service provider for each message sent
            and received.&nbsp;If you do not have a QR reader already installed
            on your phone, type ‘QR reader’ in your mobile device’s application
            store search field to find available QR readers for your smart phone
            or other web-enabled device.&nbsp;You will receive a response which
            will contain a link to download a QR reader for your specific
            device. Check with your wireless service provider for details on
            these and other applicable charges.&nbsp;Entrants are solely
            responsible for any such wireless charges.&nbsp;Not all wireless
            carriers participate.
          </p>
        </li>
      <li>
        <strong>USE OF ENTRANT INFORMATION:</strong>&nbsp;The
        information you submit in connection with this Challenge may be used by
        Sponsor for purposes of administration and fulfillment of the Challenge,
        and in accordance with Sponsor’s privacy policy located at{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.michelobultra.com/privacy-policy.html"
        >
          <span>https://www.michelobultra.com/privacy-policy.html</span>
        </a>
        {" "}(also a “Website”).&nbsp;If you are verified as a prize winner, your
        first name, last initial, city and state will be included in a publicly
        available winners list.
      </li>
      <p>
        Entrants will be given the option to receive further communication
        (i.e., e-mails, specific offers, etc.) from Sponsor; however,
        eligibility to participate in the Challenge is not dependent upon
        Entrant’s consent to receive such e-mails. Opting-in to receive further
        communication from Sponsor does not improve Entrant’s chances of
        winning. Entrants may opt-out of marketing communications at any
        time.&nbsp;
      </p>
      <li>
        <strong>WINNER SELECTION:</strong>The potential winners will be
        selected in a random drawing on or about 9/3/24 from among all eligible
        entries received during the Challenge Period by the Administrator, an
        independent judging organization. Odds of winning a Prize will depend on
        the number of eligible entries received. Limit of one (1) prize per
        person, per email address, or household.&nbsp;
      </li>
      <li>
        <strong>WINNER NOTIFICATION:</strong>
        By entering, entrants agree to be bound by these Official Rules and to
        the decisions of the Sponsor, which will be final and binding in all
        respects. On/about 9/4/24 the potential Grand Prize winners will be
        notified by email and will be required to execute and return an
        Affidavit of Eligibility, Liability and (where legal) Publicity Release
        within five (5) business days of the date of issuance of notification.
        The Grand Prize winners will be required to complete an IRS Form W9 and
        provide S/S number for tax reporting purposes. Non-compliance with the
        foregoing or with these Official Rules in any way, the inability to
        contact a potential winner (as specified above) or return of prize
        notification as non-deliverable may result in disqualification and, at
        Sponsor’s discretion, the awarding of the prize to an alternate
        potential winner in a random drawing from among all remaining entries.
        In the event of a dispute as to the identity of any entrant, entrant
        will be deemed, as applicable, the person named on the applicable entry.
      </li>
      <li>
        <strong>PRIZES:</strong>(<span className="underline">5) Grand Prizes</span>: Each Grand
        prize winner will receive a 3-day/2-night trip for the Prize winner and
        one (1) travel companion (“Guest”) to attend the Complex Pro-Am,
        currently scheduled to take place in Las Vegas, NV on/about November
        2024. Trip consists of the following elements: a) round-trip coach class
        air transportation for two (2) from a major U.S. gateway airport near
        the applicable Grand Prize winner’s residence to a major U.S. gateway
        airport near Los Angeles, CA, as determined in Sponsor/Administrator’s
        sole and exclusive discretion; b) standard double-occupancy hotel
        accommodations (one (1) room) for two (2) consecutive nights; and c) two
        (2) Event tickets. Approximate Retail Value “ARV” of each Grand Prize =
        $3,500. Total ARV of all prizes: $17,500.
      </li>
      <p>
        <strong>Prize details:</strong>
        All Grand Prize details are at Sponsor/Administrator’s sole discretion
        and are subject to change. Event tickets are subject to certain terms
        and conditions specified thereon. Seat locations (if applicable) at any
        event or activity shall be determined in the sole and absolute
        discretion of Sponsor/Administrator. Sponsor/Administrator in its sole
        discretion, reserves the right to provide ground transportation in lieu
        of air transportation if the applicable Prize winner resides within one
        hundred (150) miles of Las Vegas, NV (and no additional compensation or
        substitution will be awarded for difference in prize value). Trip must
        be taken on dates specified by Sponsor/Administrator or prize will be
        forfeited and, at Sponsor/Administrator’s discretion and time
        permitting, an alternate potential Grand Prize winner may be randomly
        selected from among all remaining eligible entries received for the
        applicable drawing.&nbsp; If the Event or any other prize-related
        activity(s) is canceled or postponed for any reason, travel prize will
        be awarded less attendance to the applicable event(s) and
        Sponsor/Administrator will substitute that activity(s) with an
        item(s)/activity(s) of approximately comparable or greater value as
        determined solely by Sponsor/Administrator. Travel and hotel
        accommodation restrictions apply. Travel arrangements must be made
        through Sponsor/Administrator’s agent and on a carrier of
        Sponsor/Administrator’s choice.&nbsp; Prize Winner and his/her guest are
        responsible for obtaining all required travel documents prior to
        travel.&nbsp; Prize Winner and his/her guest must travel together on the
        same itinerary. The Prize Winners’ guests must be 21 years of age or
        older at the time the Liability Release is due back to the
        Sponsor/Administrator). Prize winners’ guest(s) will be required to sign
        and return a Liability Release and (where legal) Publicity Release
        within five (5) days of date of issuance of notification. Prize Winner
        and his/her guest agree to comply with all event rules and regulations
        relating to the use of event tickets and, in accordance therewith, agree
        not to act in an unsportsmanlike or disruptive manner, or with any
        intent to annoy, abuse, threaten or harass any other person at such
        event(s). Sponsor/Administrator reserves the right to remove or to deny
        entry to any Prize Winner and/or respective guest who acts(s) in a
        non-sportsmanlike or disruptive manner, or with intent to annoy, abuse,
        threaten or harass any other person at the event(s).&nbsp; All prize
        details are at Sponsor/Administrator’s sole discretion. The Released
        Parties will not be responsible for weather conditions, Acts of God,
        acts of terrorism, civil disturbances, work stoppage or any other
        natural or man-made disaster that may cause the cancellation or
        postponement of the event(s). Tickets awarded as part of the Prize may
        not be resold or offered for resale. Any such resale may result in
        disqualification and prize forfeiture and may invalidate the license
        granted by the event ticket(s). Certain travel restrictions may apply.
      </p>
      <li>
        <strong>MISCELLANEOUS:</strong>No transfer, assignment, cash
        redemption, or substitution of any prize (or portion thereof) is
        permitted except by Sponsor who reserves the right to substitute the
        prize (or applicable portion thereof) with one of comparable or greater
        value, at its sole discretion. Entrants agree, by entering, that 1)
        Sponsor and its designees may use (unless prohibited by law), entrant’s
        name, city and state of residence, photograph, any recording (voice,
        film or video), and/or likeness for advertising, trade and/or any other
        purposes in any media now or hereafter known throughout the world in
        perpetuity, without further compensation, permission or notification,
        and 2) the Released Parties shall have no liability and will be held
        harmless by entrant for any claim, action, liability, loss, injury or
        damage to entrant or any other person or entity, including, without
        limitation, personal injury or death to entrant or any other person or
        damage to personal or real property, due in whole or in part, directly
        or indirectly, by reason of the acceptance, possession, use or misuse of
        any prize or participation in this Challenge. Sponsor reserves the
        right, in its sole discretion, to modify, suspend, and/or terminate this
        Challenge(or portion thereof) for any reason, including should virus,
        bugs, non-authorized human intervention or other causes corrupt or
        impair the administration, security, fairness or proper play of the
        Challenge and, in the case of termination, at its discretion, select the
        winner for the drawing at issue from eligible non-suspect entries
        received for the drawing prior to the event that required such
        termination.
        <strong>CAUTION:</strong>
        Any attempt to deliberately damage the website or undermine the
        legitimate operation of this Challenge is a violation of criminal and
        civil laws and should such an attempt be made; the Sponsor reserves the
        right to seek any and all remedies available from any such person(s)
        responsible for the attempt to the fullest extent permitted by law.
      </li>
      <p>
        SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY
        FOR INCIDENTAL, CONSEQUENTIAL, OR OTHER DAMAGES; AS A RESULT, THE ABOVE
        LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU, AND THE FOREGOING
        PARAGRAPHS SHALL NOT APPLY TO A RESIDENT OF NEW JERSEY TO THE EXTENT
        DAMAGES TO SUCH NEW JERSEY RESIDENT ARE THE RESULT OF ANY NEGLIGENT,
        FRAUDULENT OR RECKLESS ACT(S) OR INTENTIONAL MISCONDUCT ON THE PART OF
        SPONSOR OR ADMINISTRATOR.&nbsp;
      </p>
      <li>
        <strong>DISPUTE RESOLUTION</strong>: All issues and questions
        concerning the construction, validity, interpretation and enforceability
        of these Official Rules, or the rights and obligations of the entrant,
        Administrator and Sponsor in connection with the Challenge, or any claim
        or dispute that has arisen or may arise between you, Administrator and
        Sponsor, shall be governed by, and construed in accordance with, the
        laws of the State of Missouri without giving effect to any choice of law
        or conflict of law rules.&nbsp;The place of arbitration shall be St.
        Louis, Missouri.
      </li>
      <li>
        <strong>WINNERS LIST: </strong>
        For the winners’ names, (available after 9/30/24), visit
        winners.teamdigital.com/Complex no later than 11/7/24.
      </li>
      <li>
        <strong>SPONSOR: </strong>
        Anheuser-Busch, LLC, One Busch Place, St. Louis, MO 63118
      </li>
      <li>
        <strong>ADMINISTRATOR: </strong>
        teamDigital, Promotions, Inc., 6 Berkshire Blvd., Bethel, CT
        06801/www.teamdigital.com.
      </li>
      </ol>
      <p>
        The use of any non-Sponsor trademarks, service marks, logos, or other
        marks in connection with this Challenge or any prize is not meant by
        Sponsor to imply the endorsement of the respective owner(s) of such
        marks, or any affiliation of the respective owner(s) of such marks with
        Challenge advertisements or the Challenge.
      </p>
      <br/>
      <p>© 2024 Anheuser-Busch, Michelob Ultra® Light Beer, St. Louis, MO</p>
    </div>
  );
}
