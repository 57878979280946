import { useState } from "react";
import Header from "./common/Header";
import LeaderboardSlider from "./leaderboards/LeaderboardSlider";
import HeroSection from "./sections/HeroSection";
import Footer from "./common/Footer";

import "./MainContent.css";

export default function MainContent() {
  const [expanded, setExpanded] = useState(false);

  const toggleLeaderBoard = () => setExpanded(!expanded);

  return (
    <>
      <Header
        onLeaderBoardToggle={toggleLeaderBoard}
        setExpanded={setExpanded}
      />
      <div className="main-content-wrapper">
        <div className={`main-content ${expanded ? "expanded" : ""}`}>
          <HeroSection />
          <Footer />
        </div>
        <LeaderboardSlider
          expanded={expanded}
          setExpanded={setExpanded}
          toggleBoard={toggleLeaderBoard}
        />
      </div>
    </>
  );
}
