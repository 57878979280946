import "./Player.css";
import {
  truncateCourseName,
  truncatePlayerName,
  truncateFormPlayerName,
} from "../../utils";

export default function Player({ name, player, rank }) {
  const data = {
    name: "",
    score: 0,
    logoPath: "",
  };

  if (name === "grint") {
    data.name = truncatePlayerName(
      player.user.first_name,
      player.user.last_name
    );
    data.score = player.score.score;
    data.logoPath =
      "https://images.complex.com/complex/image/upload/v1717189046/Custom/michelob/TheGrint.png";
  } else if (name === "five-iron") {
    data.name = truncateFormPlayerName(player.name);
    data.score = player.score;
    data.city = player.city;
    data.logoPath =
      "https://images.complex.com/complex/image/upload/v1717447409/Custom/michelob/five-iron.png";
  } else {
    data.name = truncateFormPlayerName(player.name);
    data.score = player.score;
    data.logoPath = "";
    data.city = player.city || "";
  }

  const truncatedCourse = truncateCourseName(player.course.name);

  // prevent null scores
  if (data.score === 'null') {
    data.score = 0;
  }

  return (
    <div className="player">
      <div className="player-rank">{rank}</div>
      <div className="player-stats">
        <p>{`${data.name}`}</p>
        {name === "grint" && <p className="course">{truncatedCourse}</p>}
        {name === "five-iron" && <p className="location">{data.city}</p>}
        {name === "local" && <p className="location">{data.city}</p>}
        {name !== "local" && data.logoPath && (
          <div className="badge-logo">
            <img alt="logo" src={data.logoPath} />
          </div>
        )}
      </div>
      <div className="player-score">{data.score}</div>
    </div>
  );
}
