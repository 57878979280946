const fiveIronOptions = [
    { value: "", label: "SELECT A LOCATION", disabled: true },
    { value: "DC - Penn Quarter", label: "DC - PENN QUARTER", disabled: false },
    { value: "GA - Midtown", label: "GA - MIDTOWN", disabled: false },
    {
      value: "IN - Downtown",
      label: "IN - DOWNTOWN (Indianapolis)",
      disabled: false,
    },
    { value: "Il - Lincoln Park", label: "IL - LINCOLN PARK", disabled: false },
    { value: "Il - River North", label: "IL - RIVER NORTH", disabled: false },
    {
      value: "IL - The Metropolitan",
      label: "IL - THE METROPOLITAN",
      disabled: false,
    },
    { value: "IL - The Loop", label: "IL - THE LOOP", disabled: false },
    { value: "KY - Nulu", label: "KY - NULU", disabled: false },
    {
      value: "MA - Government Center",
      label: "MA - GOVERNMENT CENTER",
      disabled: false,
    },
    { value: "MD - Harbor East", label: "MD - HARBOR EAST", disabled: false },
    {
      value: "MI - Downtown (Detroit)",
      label: "MI - DOWNTOWN (Detroit)",
      disabled: false,
    },
    {
      value: "MI - Shelby Township",
      label: "MI - SHELBY TOWNSHIP",
      disabled: false,
    },
    { value: "NV - Area 15", label: "NV - AREA 15", disabled: false },
    { value: "NY - Fidi", label: "NY - FIDI", disabled: false },
    { value: "NY - Flatiron", label: "NY - FLATIRON", disabled: false },
    {
      value: "NY - Grand Central",
      label: "NY - GRAND CENTRAL",
      disabled: false,
    },
    {
      value: "NY - Herald Square",
      label: "NY - HERALD SQAURE",
      disabled: false,
    },
    {
      value: "NY - Long Island City",
      label: "NY - LONG ISLAND CITY",
      disabled: false,
    },
    {
      value: "NY - Upper East Side",
      label: "NY - UPPER EAST SIDE",
      disabled: false,
    },
    {
      value: "OH - Cincinnati",
      label: "OH - DOWNTOWN (Cincinnati)",
      disabled: false,
    },
    {
      value: "OH - Cleveland",
      label: "OH - DOWNTOWN (Cleveland)",
      disabled: false,
    },
    { value: "PA - Erie", label: "PA - DOWNTOWN (Erie)", disabled: false },
    { value: "PA - Fishtown", label: "PA - FISHTOWN", disabled: false },
    { value: "PA - Rittenhouse", label: "PA - RITTENHOUSE", disabled: false },
    {
      value: "PA - Market Square",
      label: "PA - MARKET SQUARE",
      disabled: false,
    },
    { value: "WA - Capitol Hill", label: "WA - CAPITOL HILL", disabled: false },
  ];

  export default fiveIronOptions;