import { useState, useEffect } from "react";
import "./AgeGate.css";

export default function AgeGate({ onVerified }) {
  const [birthDate, setBirthDate] = useState("");

  useEffect(() => {
    const storedAge = sessionStorage.getItem('bdate');
    if (storedAge && parseInt(storedAge, 10) >= 21) {
        onVerified(); // proceed if user has already entered age
    }
  }, [onVerified]);

  useEffect(() => {
    const dateInputs = document.querySelectorAll('.age-gate input[type=date]');

    dateInputs.forEach(input => {
      const handleInputChange = () => {
        if (input.value) {
          input.classList.add('has-value');
        } else {
          input.classList.remove('has-value');
        }
      };

      input.addEventListener('change', handleInputChange);

      if (input.value) {
        input.classList.add('has-value');
      }

      // Clean up event listener on component unmount
      return () => {
        input.removeEventListener('change', handleInputChange);
      };
    });
  }, []);

  const handleEnter = () => {
    const [year, month, day] = birthDate.split('-').map(Number)
    const birthdate = new Date(year, month - 1, day)
    const age = getAge(birthdate)
    if (age >= 21) {
        sessionStorage.setItem('bdate', birthDate)
        onVerified()
    } else {
        alert("Sorry, you must be at least 21 years old to access this site")
    }
  }

  const getAge = (birthdate) => {
    const today = new Date()
    const age = today.getFullYear() - birthdate.getFullYear()
    const month = today.getMonth() - birthdate.getMonth()
    if (month < 0 || (month === 0 && today.getDate() < birthdate.getDate())) {
        return age - 1
    }
    return age
  }

  return (
    <div className="gate-overlay">
      <div className="age-gate">
        <h1>HOLD UP: HOW OLD ARE YOU?</h1>
        <div className="instruction-text">
          <p>ENTER YOUR BIRTHDAY BELOW</p>
          <p>YOU MUST BE OF LEGAL DRINKING AGE TO ACCESS THIS SITE</p>
        </div>
        <input
          type="date"
          value={birthDate}
          onChange={(e) => setBirthDate(e.target.value)}
          placeholder="MM DD YYYY"
          required
        />
        <button onClick={handleEnter}>ENTER</button>
        <div className="legal-links">
          <a href="https://www.complex.com/terms" target="_blank" rel="noopener noreferrer">TERMS OF USE</a>
          <a href="https://www.complex.com/privacy" target="_blank" rel="noopener noreferrer">PRIVACY POLICY</a>
        </div>
        <div className="gate-footer">
          <img
            src="https://images.complex.com/complex/image/upload/v1717189030/Custom/michelob/mich-x-complex_wht.png"
            alt="michelob x complex logo"
          />
          <p>
            ENJOY RESPONSIBLY © 2024 ANHEUSER-BUSCH, MICHELOB ULTRA® LIGHT BEER,
            ST. LOUIS, MO.
          </p>
        </div>
      </div>
    </div>
  );
}
