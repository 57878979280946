import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import SecondaryForm from "./SecondaryForm";
import "./ScoreForm.css";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  birthdate: Yup.date().required("Birthdate is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  consent: Yup.boolean().oneOf([true], "Consent is required"),
});

export default function ScoreForm() {
  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    const storedBirthDate = sessionStorage.getItem("bdate");
    const storedName = sessionStorage.getItem("name");
    const newData = {};
    if (storedBirthDate) newData.birthdate = storedBirthDate;
    if (storedName) newData.name = storedName;
    setFormData((currentData) => ({ ...currentData, ...newData }));
  }, []);

  const formik = useFormik({
    initialValues: {
      name: formData.name || "",
      birthdate: formData.birthdate || "",
      email: "",
      consent: false,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      const endpoint = "https://formspree.io/f/mpzvbjpq";

      fetch(endpoint, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Form submitted successfully:", data);
          setFormData(values);
          setShowPopup(true);
          setSuccessMessage("THANKS FOR ENTERING!");
          setFormSubmitted(true);
        })
        .catch((error) => {
          console.error("Form submission error:", error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const handleClose = () => setShowPopup(false);

  return (
    <>
      <div className="primary-form">
        {!formSubmitted && (
          <form onSubmit={formik.handleSubmit} className="form-styling">
            <input
              id="name"
              type="text"
              name="name"
              placeholder="NAME"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoComplete="on"
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="formik-error">{formik.errors.name}</div>
            ) : null}

            <input
              id="birthdate"
              type="date"
              name="birthdate"
              placeholder="MM DD YYYY"
              value={formik.values.birthdate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.birthdate && formik.errors.birthdate ? (
              <div className="formik-error">{formik.errors.birthdate}</div>
            ) : null}

            <input
              id="email"
              type="email"
              name="email"
              placeholder="EMAIL"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoComplete="on"
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="formik-error">{formik.errors.email}</div>
            ) : null}

            <div className="opt-in">
              <label htmlFor="consent">
                I ACKNOWLEDGE AND AGREE THAT I AM A LEGAL RESIDENT OF THE (50)
                U.S. OR DC, 21 YEARS OF AGE OR OLDER AND HAVE READ AND AGREE TO
                THE <Link to="/terms">OFFICIAL RULES</Link> AND THE MICHELOB
                ULTRA{" "}
                <Link to="https://www.michelobultra.com/privacy-policy">
                  PRIVACY POLICY
                </Link>
                .
              </label>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="consent"
                  id="consent"
                  checked={formik.values.consent}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                {formik.touched.consent && formik.errors.consent ? (
                  <div className="formik-error">{formik.errors.consent}</div>
                ) : null}
              </div>
            </div>

            <button type="submit" className="submit-btn">
              ENTER
            </button>
          </form>
        )}
        {showPopup && (
          <SecondaryForm
            onClose={handleClose}
            isLeaderboardForm={false}
            initialValues={formData}
          />
        )}
        {formSubmitted && (
          <div className="success-message">{successMessage}</div>
        )}
      </div>
    </>
  );
}
