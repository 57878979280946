// API utilities

async function fetchAccessToken(username, password) {
  try {
    const response = await fetch('/v4/auth', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username, password })
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status} - Message: ${data.message}`);
    }
    return data.data.access_token;
  } catch (error) {
    console.error('Error fetching access token:', error);
    throw error;
  }
}

async function fetchActiveEvents(accessToken) {
  try {
    const response = await fetch('/v4/challenges/events', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status} - Message: ${data.message}`);
    }
    return data.data;
  } catch (error) {
    console.error('Error fetching events:', error);
    throw error;
  }
}

const truncateCourseName = (courseName) => {
  if (!courseName) return '';
  const words = courseName.split(' ');
  return words.length > 1 ? `${words[0]} ${words[1]}` : courseName;
};

const truncatePlayerName = (fullFirstName, lastName) => {
  if (!fullFirstName) {
    return ''
  };
  const capitalize = (name) => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();

  // Split the full first name to remove middle names
  const firstName = fullFirstName.split(' ')[0];

  // protect emails possibly being exposed if used as first name
  const atIndex = firstName.indexOf('@');
  const truncatedFirstName = atIndex !== -1 ? firstName.slice(0, atIndex) : firstName;

  const capitalizedFirstName = capitalize(truncatedFirstName);
  const capitalizedLastNameInitial = lastName ? lastName.charAt(0).toUpperCase() : '';

  return `${capitalizedFirstName} ${capitalizedLastNameInitial}`;
};

const truncateFormPlayerName = (fullName) => {
  if (!fullName) return 'Anonymous';
  
  const nameParts = fullName.split(' ');
  const firstName = nameParts[0].charAt(0).toUpperCase() + nameParts[0].slice(1).toLowerCase();
  
  if (nameParts.length > 1) {
    const lastNameInitial = nameParts[1].charAt(0).toUpperCase();
    return `${firstName} ${lastNameInitial}`;
  }
  
  return firstName;
};

export { fetchAccessToken, fetchActiveEvents, truncateCourseName, truncatePlayerName, truncateFormPlayerName };

