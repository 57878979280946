import { useState, useEffect, useCallback } from "react";
import Leaderboard from "./Leaderboard";
import ToggleBar from "./ToggleBar";
import leaderboardInfo from "./data/leaderboardInfo";
import "./LeaderboardSlider.css";

export default function LeaderboardSlider({
  expanded,
  setExpanded,
  toggleBoard,
  formType='default'
}) {
  const [activePopup, setActivePopup] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [totalRecords, setTotalRecords] = useState(null);
  const [error, setError] = useState(null);

  const fetchTotalRecordsCount = useCallback(async () => {
    try {
      const response1 = await fetch(`https://v-ext-michelob-ultra-api.vercel.app/api/score/grint`);
      const response2 = await fetch(`https://v-ext-michelob-ultra-api.vercel.app/api/score/leaderboards`);

      if (!response1.ok || !response2.ok) {
        throw new Error('Network response was not ok');
      }

      const recordsData1 = await response1.json();
      const recordsData2 = await response2.json();

      const localRecords = recordsData2.data?.local?.length  || 0;
      const fiveIronRecords = recordsData2.data?.fiveIron?.length || 0;
      
      const total = recordsData1.length + localRecords + fiveIronRecords;

      setTotalRecords(total);
    } catch (error) {
      console.log(error)
      setError(error);  
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth); 
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setExpanded(expanded);
  }, [expanded, setExpanded]);

  useEffect(() => {
    fetchTotalRecordsCount();
  }, [fetchTotalRecordsCount])

  const getWidth = () => {
    if (expanded) {
      return "100%";
    } else {
      return windowWidth > 768 ? "25%" : "20px";
    }
  };

  const togglePopup = (name) => {
    if (activePopup === name) {
      setActivePopup(null);
    } else {
      setActivePopup(name);
    }
  };

  return (
    <div
      className={`leaderboard-slider ${expanded ? "expanded" : ""}`}
      style={{ width: getWidth() }}
    >
      {error && <div className="error">Error: {error.message}</div>}
      <ToggleBar expanded={expanded} toggleBoard={toggleBoard} />
      <div className="total-players">
        <p>TOTAL PLAYERS: {totalRecords}</p>
      </div>
      <div className='leaderboard-container' >
        {leaderboardInfo.map((i) => {
          return (
            <Leaderboard
              {...i}
              key={i.name}
              togglePopup={() => togglePopup(i.name)}
              isPopupOpen={activePopup === i.name}
            />
          );
        })}
      </div>
    </div>
  );
}
