import React, { useState, useEffect, useCallback } from "react";
import Player from "./Player";
import SecondaryForm from "../forms/SecondaryForm";
import "./Leaderboard.css";
import loadingGif from "../../../src/images/club-swing-50.gif";

export default function Leaderboard({
  title,
  name,
  joinMessage,
  formType,
  togglePopup,
  isPopupOpen,
  numberOfRecords = 30, // Default number of records to fetch
}) {
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const link = document.createElement('link')
    link.rel = 'preload'
    link.as = 'image'
    link.href = loadingGif
    document.head.appendChild(link)

    return () => {
      document.head.removeChild(link)
    }
  }, [])

  const fetchLeaderboardData = useCallback(async () => {
    try {
      let response;
      if (formType === "default") {
        response = await fetch(
          `https://v-ext-michelob-ultra-api.vercel.app/api/score/grint?list=${numberOfRecords}`
        );
      } else {
        response = await fetch(
          `https://v-ext-michelob-ultra-api.vercel.app/api/score/leaderboards?list=${numberOfRecords}`
        );
      }

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      if (formType === "local" && 
        Array.isArray(data.data.local)) {
        setPlayers(data.data.local);
      } else if (
        formType === "five-iron" &&
        Array.isArray(data.data.fiveIron)
      ) {
        setPlayers(data.data.fiveIron);
      } else if (formType === "default" && 
        Array.isArray(data)) {
        setPlayers(data);
      } else {
        throw new Error("Data is not an array");
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [numberOfRecords, formType]);

  useEffect(() => {
    fetchLeaderboardData();
  }, [fetchLeaderboardData]);

  const renderPopupContent = () => {
    return (
      <div className="grint-popup">
        <p>TO JOIN</p>
        <h3>{title}</h3>
        <div className="instructions">
          {joinMessage.map((msg, index) => (
            <div key={index}>{msg}</div>
          ))}
        </div>
        {formType !== "default" && (
          <SecondaryForm
            formType={formType}
            onSubmit={handleFormSubmit}
            onClose={() => togglePopup(name)}
            isLeaderboardForm={true}
            refreshData={fetchLeaderboardData}
          />
        )}
      </div>
    );
  };

  // Dummy function to handle form submission
  const handleFormSubmit = (formData) => {
    console.log("Form Data:", formData);
    togglePopup(name);
  };

  if (loading) {
    return (
      <div className="loading-msg">
        <img src={loadingGif} alt="loading" className="loading-gif" />
        <div className="loading-msg">LOADING...</div>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const initialPlayers = players.slice(0, 10);
  const additionalPlayers = players.slice(10, 30);

  return (
    <div className="leaderboard-column">
      <div className="lb-header" onClick={() => togglePopup(name)}>
        <h2>{title}</h2>
        <p className="join">
          <a
            href="#!"
            onClick={(e) => {
              e.preventDefault();
              togglePopup(name);
            }}
          >
            HOW TO JOIN THE LEADERBOARD
          </a>
        </p>
      </div>
      <div className="lb-wrap initial-records">
        {initialPlayers.map((player, index) => (
          <Player
            name={name}
            key={index}
            player={player}
            rank={index + 1}
            fiveIronLocation={player.location}
          />
        ))}
      </div>
      <div className="lb-wrap additional-records">
        {additionalPlayers.map((player, index) => (
          <Player
            name={name}
            key={index + 10}
            player={player}
            rank={index + 11}
            fiveIronLocation={player.location}
          />
        ))}
      </div>
      {isPopupOpen && (
        <div className="popup">
          <button className="pop-close" onClick={() => togglePopup(name)}>
            &times;
          </button>
          <div className="popup-inner">{renderPopupContent()}</div>
        </div>
      )}
    </div>
  );
}
